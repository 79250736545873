import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Hero from "../components/Hero/HeroSplit";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  return (
    <Layout>
      <SearchEngineOptimization
        title="The 5-Step Loan Process | Thistle Financial"
        description="Get started on your home loan with the 5-step loan process. At Thistle, we work closely with you to move you through every touchpoint from start to close."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <Hero
        image={data.heroDesktop.childImageSharp.gatsbyImageData}
        imageAlignment="right"
        textWidth="1/2"
        imageWidth="1/2"
        minHeight="md:min-h-[626px]"
        padding="pb-16 md:pb-0"
      >
        <h1>The 5-Step Loan Process</h1>
        <p>
          Having the right mortgage company by your side is key to a smooth loan
          process. At Thistle, we work closely with you to move you through
          every touchpoint from start to close. And before you know it, you’ll
          have the keys to your new home in hand.
        </p>
        <ButtonSolid href="/request-rates/" text="Request Rates" />
      </Hero>

      <section className="bg-primary-50 py-20 md:py-32">
        <div className="container">
          <header className="max-w-2xl mb-16 md:mb-20">
            <h2>Loan Process Heading</h2>
          </header>

          <ScrollWrapper className="grid lg:grid-cols-12 gap-y-16 lg:gap-x-20">
            <ScrollNavigation className="lg:col-start-1 lg:col-span-4 hidden md:block relative after:content after:absolute after:right-0 after:top-0 after:w-px after:h-full after:bg-primary-600/30">
              <li>
                <AnchorLink
                  to={url + "#anchor-1"}
                  title="1 - How Much Can I borrow"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-2"}
                  title="2 - Select Loan Program"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-3"}
                  title="3 - Apply"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-4"}
                  title="4 - Loan Processing"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-5"}
                  title="5 - Close"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
            </ScrollNavigation>

            <ScrollContent className="lg:col-end-13 lg:col-span-8">
              <div
                id="anchor-1"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">1. Find Out How Much You Can Borrow</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Before you even begin looking for a home, you need to know
                  exactly how much you can borrow and get pre-qualified. That
                  way you can look at properties that are in your price range,
                  be in a better position when negotiating with a seller, and
                  close your loan quicker.
                </p>
              </div>

              <div
                id="anchor-2"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">2. Select Your Loan Program</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Some home loans offer fixed rates with terms lasting 15 or 30
                  years, and your monthly payments are always the same. Others
                  offer adjustable rates where the interest rate periodically
                  goes up or down, changing your monthly payment. Naturally,
                  you’ll want to choose one that makes the most sense for your
                  finances and goals.
                </p>
              </div>

              <div
                id="anchor-3"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">3. Apply For A Loan</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Start by gathering the documents you’ll need. That includes
                  tax returns, pay stubs, W-2s, other proof of income, bank
                  statements, and documentation about any other assets you may
                  have. Next, fill out and submit a loan application. You may be
                  asked at the same time to upload some of the documents you
                  gathered.
                </p>
              </div>

              <div
                id="anchor-4"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">4. Begin Loan Processing </h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Once your loan application has been received, we’ll start the
                  loan approval process immediately. First, your loan processor
                  will verify all of the information you have given, and whether
                  there is anything further you need to provide. The important
                  thing to remember at this stage is not to make any major
                  purchases until your loan is closed.
                </p>
              </div>

              <div
                id="anchor-5"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-10 md:pb-12"
              >
                <h3 className="mb-1">5. Close Your Loan</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Just before closing, you’ll be invited to take a walk-through
                  of your home to ensure that any requested repairs have been
                  completed and that any items it was agreed would stay in the
                  house (such as appliances) are included. Then you’ll sign the
                  final paperwork, deliver your closing funds (typically via a
                  cashier’s check or wire transfer), and get your keys to your
                  new home!
                </p>
              </div>
            </ScrollContent>
          </ScrollWrapper>
        </div>
      </section>

      <Testimonials />
      <ValueProps />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(relativePath: { eq: "open-graph/facebook/5 step" }) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/5 step" }
    ) {
      publicURL
    }
    heroDesktop: file(relativePath: { eq: "2.6 Loan Process/hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 680)
      }
    }
  }
`;
export default Page;
